import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /*
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  */

  render() {
    const { hasError } = this.state;
    const { children, renderFallback } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      if (renderFallback) {
        return renderFallback();
      }
      return <h1>Es ist ein Fehler aufgetreten.</h1>;
    }

    return children;
  }
}
