import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  useCallback
} from "react";
import axios from "axios";

const PwaContext = createContext();

const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

const checkConnection = async () => {
  try {
    const versionFile = await axios.get(
      `${process.env.PUBLIC_URL}/version.json`,
      { timeout: 5000 }
    );
    console.log("checkConnection axios result", versionFile);
    // do not use versionFile.statusText !== "OK", its a "bug" on https where statustext is empty
    // see: https://github.com/axios/axios/issues/1501
    if (versionFile.status !== 200) {
      return {
        connected: false
      };
    }
    return { connected: true, version: versionFile.data.version };
  } catch (error) {
    console.log("checkConnection error", error);
    return {
      connected: false
    };
  }
};

const getDocumentVisibilityState = () => {
  if (document.visibilityState === "hidden") {
    return "hidden";
  }
  if (document.hasFocus()) {
    return "active";
  }
  return "passive";
};

const usePwa = (appInfo) => {
  const [pwaState, setPwaState] = useState({
    pending: true,
    state: "starting"
  });
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [visibilityLog, setVisibilityLog] = useState([]);
  const blockCheckingForUpdate = useRef(false);

  // on start app (maybe from cache)
  useEffect(() => {
    const intializeServiceworkerHandler = async () => {
      try {
        blockCheckingForUpdate.current = true;
        const checkedConnection = await checkConnection();
        if (!checkedConnection.connected) {
          console.log("--conneciton failed.");
          setPwaState({ pending: false, state: "connection-failed" });
          return;
        }
        setPwaState({ pending: true, state: "registering" });
        const registration = await navigator.serviceWorker.register(swUrl);
        setPwaState({ pending: true, state: "registered" });
        console.log("🧩 Service worker registered.");

        const currentVersion = appInfo.version;
        const availableVersion = checkedConnection.version;
        if (availableVersion && currentVersion !== availableVersion) {
          console.log("🆕 UPDATE_AVAILABLE!");
          setPwaState({ pending: true, state: "updating" });
          registration.addEventListener("updatefound", () => {
            if (registration.installing) {
              console.log("we have a installing registration!");
              registration.installing.addEventListener(
                "statechange",
                ({ currentTarget: installingServiceWorker }) => {
                  const installState = installingServiceWorker.state;
                  console.log("state of installing", installState);
                  if (installState === "activated") {
                    console.log(
                      "🧩 Service worker is installed and activated. Restart the app?"
                    );
                    setPwaState({ pending: true, state: "restarting" });
                    installingServiceWorker.postMessage({
                      type: "FORCE_RESTART_OTHERS"
                    });
                    setTimeout(() => window.location.reload(), 2000);
                  } else if (installState === "redundant") {
                    // is outdated, or cant intalled through errors
                    // we should stop the initializing process?!
                    setPwaState({ pending: false, state: "updatefailed" });
                  }
                }
              );
            } else if (registration.waiting) {
              // this can happend, if an update was break (eg. reload while updating, or device power off...)
              setPwaState({ pending: true, state: "updating from wait" });
              registration.waiting.postMessage({ type: "SKIP_WAITING" });
              setPwaState({ pending: true, state: "restarting" });
              setTimeout(() => window.location.reload(), 2000);
            }
          });
        } else {
          setPwaState({ pending: false, state: "done" });
        }
      } catch (error) {
        setPwaState({ pending: false, state: `error-${error}` });
      } finally {
        blockCheckingForUpdate.current = false;
      }

      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data === "FORCE_RESTART") {
          setPwaState({ pending: true, state: "restarting" });
          setTimeout(() => window.location.reload(), 2000);
        }
      });
    };

    if (process.env.NODE_ENV !== "production") {
      setPwaState({ pending: false, state: "done" });
    } else {
      intializeServiceworkerHandler();
    }
  }, [appInfo.version]);

  useEffect(() => {
    const onlineListener = () => {
      setIsOnline(window.navigator.onLine);
    };
    window.addEventListener("online", onlineListener);
    window.addEventListener("offline", onlineListener);

    return () => {
      window.removeEventListener("online", onlineListener);
      window.removeEventListener("offline", onlineListener);
    };
  }, []);

  const checkForUpdate = async (onUpdateAvailable = () => {}) => {
    if (blockCheckingForUpdate.current) {
      // we are checking already
      return;
    }
    const currentVersion = appInfo.version;
    const checkedConnection = await checkConnection();
    if (!checkedConnection.connected) {
      onUpdateAvailable(currentVersion, null);
    }
    const availableVersion = checkedConnection.version;
    onUpdateAvailable(currentVersion, availableVersion);
    blockCheckingForUpdate.current = false;
  };

  return {
    pending: pwaState.pending,
    state: pwaState.state,
    isOnline,
    visibilityLog,
    checkForUpdate
  };
};

export const usePwaContext = () => useContext(PwaContext);

export default ({ appInfo = {}, children }) => {
  const [updateIsAvailable, setUpdateIsAvailable] = useState(false);
  const [installPromotionIsVisible, setinstallPromotionIsVisible] =
    useState(false);
  const pwa = usePwa(appInfo);

  useEffect(() => {
    const updateListener = () => {
      const documentVisibilityState = getDocumentVisibilityState();
      if (documentVisibilityState === "active") {
        pwa.checkForUpdate((currentVersion, availableVersion) => {
          if (availableVersion && currentVersion !== availableVersion) {
            setUpdateIsAvailable(true);
          } else {
            setUpdateIsAvailable(false);
          }
        });
      }
    };

    const eventTypes = [
      "pageshow",
      "focus",
      "blur",
      "visibilitychange",
      "resume",
      "online"
    ];
    eventTypes.forEach((type) => {
      window.addEventListener(type, updateListener);
    });

    return () => {
      eventTypes.forEach((type) => {
        window.removeEventListener(type, updateListener);
      });
    };
  });

  const deferredInstallPrompt = useRef(null);
  useEffect(() => {
    const beforeInstallPromptListener = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredInstallPrompt.current = e;
      // Update UI notify the user they can install the PWA
      setinstallPromotionIsVisible(true);
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    };
    window.addEventListener("beforeinstallprompt", beforeInstallPromptListener);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptListener
      );
    };
  }, []);

  const handleDeferredInstallPromotion = useCallback(async () => {
    if (deferredInstallPrompt.current) {
      // Show the install prompt
      deferredInstallPrompt.current.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredInstallPrompt.current.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // hide the promotion
      setinstallPromotionIsVisible(true);
      // We've used the prompt, and can't use it again, throw it away
      deferredInstallPrompt.current = null;
    }
  }, []);

  return (
    <PwaContext.Provider
      value={{
        appInfo,
        pending: pwa.pending,
        isOnline: pwa.isOnline,
        state: pwa.state,
        updateIsAvailable,
        installPromotionIsVisible,
        closeInstallPromotion: handleDeferredInstallPromotion
      }}
    >
      {children}
    </PwaContext.Provider>
  );
};
