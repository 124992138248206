import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

const appInfo = {
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION
};

ReactDOM.render(
  <React.StrictMode>
    <App appInfo={appInfo} />
  </React.StrictMode>,
  document.getElementById("root")
);
