import React from "react";
import ErrorBoundary from "./components/basics/ErrorBoundary";
import PWA, { usePwaContext } from "./containers/app/PWA";

const App = () => {
  const pwa = usePwaContext();

  return (
    <div style={{ color: pwa.pending ? "red" : "green" }}>
      <h1>PWA</h1>
      {pwa.updateIsAvailable && (
        <div>
          <p>Update available.</p>
          <button
            type="button"
            onClick={() => {
              window.location.reload();
            }}
          >
            Restart
          </button>
        </div>
      )}
      {pwa.installPromotionIsVisible && (
        <div style={{ border: "2px solid black", padding: "1rem" }}>
          <h2>Install this as an app!</h2>
          <p>Why? Lorem Ipsum.</p>
          <p>Your device will ask you now.</p>
          <button
            type="button"
            onClick={() => {
              pwa.closeInstallPromotion();
            }}
            disabled={pwa.pending}
          >
            Ok (close promotion)
          </button>
        </div>
      )}
      <h1>{pwa.state}</h1>
      {pwa.state === "connection-failed" && pwa.isOnline && (
        <>
          Dein device ist online, aber der server antwortet nicht.
          <button type="button" onClick={() => window.location.reload()}>
            Nochmal versuchen
          </button>
        </>
      )}
      {!pwa.isOnline && (
        <div>
          Dein device ist offline.
          <button type="button" onClick={() => window.location.reload()}>
            Nochmal versuchen
          </button>
        </div>
      )}
      <pre>{JSON.stringify(pwa.visibilityLog, null, 2)}</pre>
      <pre>{JSON.stringify(pwa.appInfo, null, 2)}</pre>
      <pre>{JSON.stringify(window.location, null, 2)}</pre>
    </div>
  );
};

export default ({ appInfo = {} }) => (
  <div className="app">
    <ErrorBoundary
      renderFallback={() => (
        <div>
          <h1>Oh nein!</h1>
          <button type="button" onClick={() => window.location.reload()}>
            Neu starten
          </button>
        </div>
      )}
    >
      <PWA appInfo={appInfo}>
        <App />
      </PWA>
    </ErrorBoundary>
  </div>
);
